// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-case-js": () => import("./../../../src/pages/business-case.js" /* webpackChunkName: "component---src-pages-business-case-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eco-impact-js": () => import("./../../../src/pages/eco-impact.js" /* webpackChunkName: "component---src-pages-eco-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-news-js": () => import("./../../../src/pages/industry-news.js" /* webpackChunkName: "component---src-pages-industry-news-js" */),
  "component---src-pages-invasive-species-js": () => import("./../../../src/pages/invasive-species.js" /* webpackChunkName: "component---src-pages-invasive-species-js" */),
  "component---src-pages-our-background-js": () => import("./../../../src/pages/our-background.js" /* webpackChunkName: "component---src-pages-our-background-js" */),
  "component---src-pages-our-kilns-js": () => import("./../../../src/pages/our-kilns.js" /* webpackChunkName: "component---src-pages-our-kilns-js" */),
  "component---src-pages-our-partners-js": () => import("./../../../src/pages/our-partners.js" /* webpackChunkName: "component---src-pages-our-partners-js" */),
  "component---src-pages-our-research-js": () => import("./../../../src/pages/our-research.js" /* webpackChunkName: "component---src-pages-our-research-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-shipping-js": () => import("./../../../src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

