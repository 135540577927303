module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":1,"once":true,"selector":"[data-kin]","animateClassName":"kin-animate","disabledClassName":"kin-disabled","rootMargin":"0% 50%","enterEventName":"kin:in","exitEventName":"kin:out"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RF Kiln","short_name":"RF Kiln","start_url":"/","background_color":"#FFFFFF","theme_color":"#53305F","display":"minimal-ui","icon":"src/images/img/favicon-2.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8e49aaa29d4bee23bb55f2afbd1153cc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
